import React, { FC } from "react";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../Wow";

interface IProductHeader {
  title?: string;
  customTitle?: string;
  linkTitle?: string;
  isProduct?: boolean;
  isMarket?: boolean;
  isTitleH1?: boolean;
}

const ProductHeader: FC<IProductHeader> = ({
  title = "",
  customTitle = "",
  linkTitle = "",
  isProduct = false,
  isMarket = false,
  isTitleH1 = false,
}) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isTitleH1)
      return (
        <h1 className="h1 font-weight-semi-bold text-uppercase mb-3 text-white">
          {customTitle || title}
        </h1>
      );

    return (
      <h4 className="h1 font-weight-semi-bold text-uppercase mb-3 text-white">
        {customTitle || title}
      </h4>
    );
  };

  return (
    <ComponentWow>
      <div
        className={
          isMarket
            ? "container-fluid bg-secondary"
            : "container-fluid bg-secondary mb-5"
        }
      >
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "300px" }}
        >
          {renderTitle()}
          <div className="d-inline-flex">
            <p className="m-0">
              <Link to="/">{t("menu.home")}</Link>
            </p>
            {isProduct && (
              <>
                <p className="m-0 px-2">-</p>
                <p className="m-0">
                  <Link to="/products">{t("menu.products")}</Link>
                </p>
              </>
            )}
            {/* {isMarket && (
              <>
                <p className="m-0 px-2">-</p>
                <p className="m-0">
                  <Link to="/markets">{t("menu.market")}</Link>
                </p>
              </>
            )} */}
            <p className="m-0 px-2">-</p>
            <p className="m-0">{linkTitle || title}</p>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default ProductHeader;
